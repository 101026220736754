import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import ImageTile from 'ol/source/ImageTile.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {Circle, Fill, Icon, Stroke, Style, Text} from 'ol/style.js';

if (!fabuly.defaultViewZoomLevel) {
  fabuly.defaultViewZoomLevel = 10;
}
if (!fabuly.defaultViewCenter) {
  fabuly.defaultViewCenter = [
      1031415, 6426844
  ];
}

const iconStyleCache = {}
const getIconStyle = (url) => {
  if (url in iconStyleCache) {
    return iconStyleCache[url]
  }
  iconStyleCache[url] = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorOrigin: 'bottom-left',
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: .5,
      src: url,
    })
  })
  return iconStyleCache[url]
}

const layers = {

  background: new TileLayer({
    source: new ImageTile({
      url: `${fabuly.layers.background.url}`
    })
  }),

  gemeinden: new VectorLayer({
    source: new VectorSource({
      url: fabuly.layers.gemeinden.url,
      format: new GeoJSON(),
    }),
  }),

  gemeindenHighlight: new VectorLayer({
    source: new VectorSource(),
    style: {
      'stroke-color': 'rgba(255, 155, 155, 0.7)',
      'stroke-width': 3,
    },
  }),

  punkte: new VectorLayer({
    source: new VectorSource({
      url: fabuly.layers.punkte.url,
      format: new GeoJSON(),
    }),
    style: (feature) => {
      const icon = feature.get('icon')
      return getIconStyle(icon);
    }
  }),

  punkteHighlight: new VectorLayer({
    source: new VectorSource(),
    style: (feature) => {
      const highlightIcon = feature.get('highlight_icon')
      return getIconStyle(highlightIcon)
    }
  })

};

const view = new View({
  center: fabuly.defaultViewCenter,
  zoom: fabuly.defaultViewZoomLevel,
  minZoom: 10,
  maxZoom: 17
});

const mapLayers = (layersDict) => {
  let res = []
  for (let k in layersDict) {
    res.push(layersDict[k])
  }
  return res
}

const map = new Map({
  target: 'map',
  layers: mapLayers(layers),
  view: view,
});


// when map clicked:
// -> highlight feature if layer has a highlight layer
// -> call fabuly.featureInfoCallback(feature, layerName)
let selectedFeature, selectedFeatureLayerName;
map.on('click', (e) => {
  let feature, layerName = null;
  for (let k in layers) {
    if (k.includes("Highlight")) {
      continue;
    }
    let layerFeature = map.forEachFeatureAtPixel(e.pixel, 
      (feature) => { return feature; },
      {
        layerFilter: (layerCandidate) => { 
          return layerCandidate == layers[k]
        }
      }
    );
    if (layerFeature) {
      feature = layerFeature
      layerName = k;
    }
  }
  if (feature !== selectedFeature) {
    if (selectedFeature) {
      layers[selectedFeatureLayerName+"Highlight"].getSource().removeFeature(selectedFeature);
    }
    if (feature && layerName+"Highlight" in layers) {
      layers[layerName+"Highlight"].getSource().addFeature(feature);
    }
    selectedFeature = feature;
    selectedFeatureLayerName = layerName;

    if (window.fabuly && window.fabuly.featureInfoCallback) {
      window.fabuly.featureInfoCallback(selectedFeature, selectedFeatureLayerName);
    }
  }
});

fabuly.setLayerUrl = function(layerName, dataUrl=false) {
  if (layerName in layers) {
    if(dataUrl) {
      fabuly.layers[layerName].url = dataUrl; // update config?
      layers[layerName].getSource().setUrl(dataUrl);
    }
    layers[layerName].getSource().refresh({force: true});
  }
}

fabuly.resetView = function() {
  view.setZoom(fabuly.defaultViewZoomLevel);
  view.setCenter(fabuly.defaultViewCenter);
}